import {useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import Cookies from "js-cookie";
import axios from "axios";

const AuthorizeCallbackComponent = () => {
    let [searchParams] = useSearchParams();
    let apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        let code = searchParams.get('code');
        let customerId = Cookies.get('cid');
        if (code && customerId) {
            axios.get(`/api/callback?code=${code}&cid=${customerId}`)
                .then(function (response) {
                    if (response.data.status === 'successful') {
                        window.location.href = apiUrl;
                    } else {
                        return (
                            <>Authorize failed</>
                        )
                    }
                })
        } else {
            return (
                <>Authorize failed</>
            )
        }
    }, [searchParams, apiUrl]);
}

export default AuthorizeCallbackComponent
